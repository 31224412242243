<template>
  <div class="section">
    <modal-response
      v-if="modalAddResponse"
      :close="openModal"
      :saveResponse="saveResponse"
    />
    <modal-resolve
      v-if="modalAddResolve"
      :close="openModalResolve"
      :saveCloseResponse="saveCloseResponse"
    />
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1>Detalle del reclamo</h1>
        </div>
      </div>
      <div class="level-right" v-if="claimsList.statusId === OPEN_STATUS">
        <div class="level-item">
          <button class="button" @click="openModalResolve">Resolver</button>
        </div>
        <div class="level-item">
          <button class="button button-givu" @click="openModal">Responder</button>
        </div>
      </div>
    </nav>
    <div class="columns is-multiline">
      <div class="column is-6">
        <claim-detail
          :detail="claimsList"
        />
      </div>
      <div class="column is-12 table-content">
        <base-table
          class="is-bordered is-striped is-narrow is-fullwidth is-hoverable bankingTable"
          :columns="columnsData"
          :data="claimsMessages"
          :loading="loading"
        >
        </base-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import mixin from '../../mixin/validateDate'
export default {
  name: 'ClaimsDetail',
  mixins: [mixin],
  data () {
    return {
      OPEN_STATUS: 1,
      page: 1,
      pages: 1,
      claimId: this.$route.params.claimId,
      loading: false,
      modalAddResponse: false,
      modalAddResolve: false,
      claimsList: {},
      claimsMessages: [],
      columnsData: [
        { id: 'id', header: 'ID', class: 'column-mobile column-id' },
        { id: 'message', header: 'Mensaje', class: 'column-mobile column-message' },
        { id: 'is_user', header: 'Tipo', class: 'column-mobile', accessor: (row) => row.is_user ? 'Usuario' : 'Administrador' },
        { id: 'created_at', header: 'Fecha', class: 'column-mobile', accessor: (row) => this.formatDateTimestamp(row.created_at) },
        { id: 'username', header: 'Usuario', class: 'column-mobile' },
        { id: 'email', header: 'Administrador', class: 'column-mobile' }
      ]
    }
  },
  components: {
    ClaimDetail: () => import('@/components/Reclamos/ClaimDetail'),
    ModalResponse: () => import('@/components/Reclamos/ModalResponse'),
    ModalResolve: () => import('@/components/Reclamos/ModalResolve'),
    BaseTable: () => import('@/components/Table')
  },
  methods: {
    ...mapActions(['getClaimsMessages', 'responseClaims', 'closeClaims']),
    openModal () {
      this.modalAddResponse = !this.modalAddResponse
    },
    openModalResolve () {
      this.modalAddResolve = !this.modalAddResolve
    },
    saveResponse (message) {
      this.responseClaims({ claimId: this.claimId, message })
        .then(response => {
          this.getClaimsMessages({ claimId: this.claimId, page: 1 })
            .then(messages => {
              this.claimsMessages = messages.payload.messages
            })
          this.openModal()
        })
    },
    saveCloseResponse (response) {
      this.closeClaims({ claimId: this.claimId, ...response })
        .then(response => {
          this.openModalResolve()
        })
    }
  },
  beforeMount () {
    this.loading = true
    this.getClaimsMessages({ claimId: this.claimId, page: 1 })
      .then(messages => {
        this.claimsList = messages.payload
        this.claimsMessages = messages.payload.messages
        this.loading = false
      })
  }
}
</script>

<style scoped>
  .section {
    width: 100%;
    padding: 0 20px 0 20px;
  }
  .table-content {
    margin-bottom: 40px;
  }
</style>

<style>
  .table.is-narrow .column-id {
    width: 20px;
  }
  .table.is-narrow .column-message {
    width: 450px;
  }
</style>
